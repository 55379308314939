.heading {
  font-family: "Franklin Gothic Book";
  font-size: 40px;
  font-weight: 800;
  text-align: Left;
  margin-top: 30px;
  margin-bottom: 30px;
}
#kontakt {
  margin-left: 12px;
}
#sluzby {
  margin-left: 12px;
}
.load {
  transition: ease-out 1s;
  margin-top: 150px;
}
.back {
  background-color: #b2b2b2;
  height: 100vh;
  width: 100vw;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}
#loadlogo {
  max-width: 360px;
  max-height: 101px;
  background-image: linear-gradient(
      -45deg,
      transparent 50%,
      rgb(255, 255, 255) 50%
    ),
    linear-gradient(45deg, rgb(255, 255, 255) 50%, transparent 50%);
  background-size: 200% 200%; /* zajistí, že gradienty pokryjí celé pozadí */
  background-repeat: no-repeat; /* zajistí, že se gradienty nebudou opakovat */

  animation: shiny 3s linear infinite; /* 3s = Doba trvání animace */
}
@keyframes shiny {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.portofolioNew .row {
  --bs-gutter-x: 0;
}
#reference {
  margin-left: 12px;
}
.grid-item {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  cursor: pointer;
  opacity: 0;
  transition: 150ms;
}
.grid-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.relative {
  position: relative;
}

.none {
  display: none;
}

@keyframes square {
  0% {
    width: 0;
    height: 0;
  }

  100% {
    width: 90%;
    height: 90%;
  }
}
.color-white {
  color: white;
}

.overlay {
  position: absolute;
  color: rgb(0, 0, 0);
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: 0.4s ease-in-out;
}
.overlay:hover {
  opacity: 1;
}
.margin-overlay {
  margin: 8%;
}
.actions {
  position: absolute;
  left: 8%;
  bottom: 8%;
}

.italic {
  font-style: italic;
  font-weight: 300;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.grid-item.animate {
  animation: FadeIn forwards 1s;
}

.photo-line {
  transition: 0.15s;
  opacity: 1;
  margin: 0;
  border: 2px solid;
  color: #ffcc00;
}
.active {
  color: #2d2d2d;
}
.photo-button {
  border: none;
  margin-left: 50%;
  transform: translate(-50%, -1px);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-bottom: 20px;
}
.mrg-12 {
  margin-left: 12px;
}

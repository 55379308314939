.navi {
  font-family: Arial, Helvetica, sans-serif;
  z-index: 999;
  position: -webkit-sticky;
  /* position: sticky; */
  top: 0;
  font-size: large;
}
.logo {
  height: 70px;
  width: 250px;
}
.logo img {
  width: 100%;
}
#portfolio {
  transition: padding-top 0.3s ease;
}

/* .true {
  transition: 1s;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-end-end-radius: 20px;

  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
} */

.items {
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  color: rgb(77, 77, 77);
}
.items.active {
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
  border-radius: 10%;
}
@media (hover: hover) {
  .items:hover {
    font-weight: 400;
    color: rgb(0, 0, 0);
    border-radius: 10%;
  }
}
.navbar-nav {
  margin-left: auto;
}
.nav-item {
  margin: auto 15px;
}
.navbar-toggler:focus {
  box-shadow: none;
}

#custom-toggler {
  padding: 10;
  background-color: transparent;
  border: 0;
}
.toggler-image img {
  width: 40px;
  height: 40px;
}
.toggler-image {
  transition: transform 0.5s;
}

.rotate {
  transform: rotate(180deg);
}
.to-top {
  transition: 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 3%;
  right: 3%;
}
.button-up-visible {
  visibility: visible;
  opacity: 1;
  z-index: 999;
}
@media only screen and (max-width: 380px) {
  .logo {
    width: 70%;
  }
  #custom-toggler {
    padding: 0;
  }
}

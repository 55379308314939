.uvodka {
  object-fit: cover;
  height: 620px;
  width: 100%;
  position: absolute;
}
.just {
  text-align: justify;
}
.just h2 {
  text-align: center;
}
.centercenter {
  margin: auto;
  text-align: center;
}
.imgCont {
  position: relative;
  height: 620px;
  width: 100%;
}
.www {
  height: 100%;
}
.uvodTxt {
  border: 1px solid white;
  margin-left: auto;
  text-align: justify;
  position: relative;
  width: 400px;
  top: 13%;
  right: 15px;
}
@media only screen and (max-width: 768px) {
  .uvodTxt {
    text-align: center;
    top: 13%;
    right: 50%;
    transform: translateX(+50%);
  }
  .inzenyr .show-sm {
    visibility: visible;
    /* display: block; */
  }
}
@media only screen and (max-width: 430px) {
  .uvodTxt {
    text-align: center;
    top: 10%;
    right: 50%;
    transform: translateX(+50%);
    width: 100%;
  }
  .inzenyr .show-sm {
    visibility: visible;
    /* display: block; */
  }
}
@media only screen and (min-width: 800px) {
  .inzenyr .show-sm {
    display: none;
  }
}
.pluska {
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
}
.inzenyr {
  font-weight: 300;
  margin-bottom: 0rem;
}
.noGutter {
  --bs-gutter-x: 0;
}

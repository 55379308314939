.footerCenter {
  text-align: center;
}
.footerCenter h5 {
  color: black;
  font-weight: 400;
  line-height: 17px;
}
.normalLink {
  text-decoration: none;
  color: black;
}
@media (hover: hover) {
  .normalLink:hover {
    transition: ease-in-out 0.5s;
    color: #ffcc00;
  }
}
.copyright p {
  margin-top: 40px;
  margin-bottom: 0%;
  font-weight: 500;
  /* border-left: 2px solid black;
  border-top: 2px solid black;
  border-right: 2px solid black; */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /* background-color: #ffcc00; */
  background: radial-gradient(circle, rgba(255, 187, 0, 1) 0%, transparent 55%);
}

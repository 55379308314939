.imgcenter img {
  margin: auto;
  transition: ease-in-out 0.5s;
}
.imgcenter {
  background-color: transparent;
}
.cara {
  border: 2px solid black;
  opacity: 1;
  margin-bottom: 30px;
}
.cardText {
  text-align: justify;
}
.cartTitlecenter {
  text-align: center;
  font-weight: 600;
}
.border-light {
  --bs-border-opacity: 0;
}
#dozor {
  font-weight: 900;
}
.gray {
  --bs-gutter-x: 0;
  border-radius: 20px;
  padding-bottom: 30px;
  background-color: #b2b2b279;
}
